container {
    height: 100vh;
    /* background-image: url(../../assets/Calendar_BackgroundOfTextarea2.png); */
    background-repeat: no-repeat;
    background-position: bottom;
  }
  
  .calendar {
    display: grid;
    justify-content: center;
    padding-top: 1rem;
  }
  
  .textarea {
    display: grid;
    justify-items: center;
  }
  
  .navigation {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    right: 0;
  }
  
  .button {
    all: unset;
  }
