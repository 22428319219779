.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

button {
  max-width: 150px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

button + button {
  margin-left: 0.5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.formRow input,
.formRow.checkoutInput select {
  display: block;
  width: 100%;
  padding: 10px 5px;
  line-height: 1;
  text-align: left;
  border: 1px solid #9e9e9e;
  outline: none;
}

b {
  color: #555;
}

.dropdown-toggle::after {
  display: none !important; 
}
